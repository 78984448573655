import { ChainId } from '@uniswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53749b3ce4d93766cfb6ae828eccebfeecfc5686',
  [ChainId.KOVAN]: '0x53749b3ce4d93766cfb6ae828eccebfeecfc5686',
  [ChainId.RINKEBY]: '0x53749b3ce4d93766cfb6ae828eccebfeecfc5686',
  [ChainId.GÖRLI]: '0x53749b3ce4d93766cfb6ae828eccebfeecfc5686'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
